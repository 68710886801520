<template>
  <div class="web-item">
    <div class="item-title-container">
      <div class="item-title" @click="$parent.onClickTopic(webItem.id)">
        {{ webItem.title }}
      </div>
    </div>
    <div class="ft-model-3s" v-if="webItem.model == '3s'" @mouseenter="stopTimer()" @mouseleave="restartTimer()">
      <div
        v-for="(pic, contentIndex) in webItem.list"
        :key="contentIndex + 'FT' + pic.id"
        class="pic"
        :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
        :class="{ active: content.picIndex == contentIndex }"
        @click="$parent.onLookPost(pic.postId)"
      ></div>
      <div class="text">
        <div
          v-for="(pic, navIndex) in webItem.list"
          :key="'ft_' + pic.id + '_nav' + '_' + navIndex"
          class="nav-pic"
          :class="{ active: content.picIndex == navIndex }"
          @click="scrollTo(navIndex)"
        ></div>
      </div>
    </div>

    <!-- 固定栏目其它 -->
    <div :class="'ft-model-' + webItem.model" v-else>
      <div class="post-con" v-for="post in webItem.list" :key="'ft' + post.postId">
        <div class="post-img" :style="{ backgroundImage: `url(` + post.imgURL + `)` }" @click="$parent.onLookPost(post.postId)"></div>
        <div class="post-text">{{ post.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'

export default {
  name: 'IndexFt',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return { webItem: {} }
  },
  created() {
    this.reload()
  },
  destroyed() {
    clearInterval(timers)
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.list.forEach((i) => {
          if (i.postId) {
            i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
          }
          if (this.content.model == '3s') {
            i.imgURL = RD.pic().id(i.frontPicId).tImage_URL()
          }
        })
        this.webItem = this.content
      }, 1000)
      if (timers) {
        clearInterval(timers)
      }
      timers = setInterval(() => {
        this.nextsky()
      }, 3000)
    },
    scrollTo(navIndex) {
      this.content.picIndex = navIndex
    },
    nextsky() {
      var thiz = this
      thiz.content.picIndex++
      if (thiz.content.picIndex >= thiz.content.list.length) {
        thiz.content.picIndex = 0
      }
    },
    stopTimer() {
      clearInterval(timers)
    },
    restartTimer() {
      if (timers) {
        clearInterval(timers)
      }
      timers = setInterval(() => {
        this.nextsky()
      }, 2000)
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ft-con {
    width: 750px;
    margin: 1.7rem auto;
    position: relative;
    .post-con {
      cursor: pointer;
    }

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-img {
      width: 100%;
      height: 100%;
      background: no-repeat 50% / cover;
      transition: all 500ms ease-in-out;
    }

    .post-text {
      position: absolute;
      bottom: 0.66rem;
      left: 50%;
      transform: translate(-50%);
      width: 85%;
      border-radius: 0.42rem;
      font-size: 1.3rem;
      text-align: center;
      height: 3.9rem;
      line-height: 3.9rem;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      transition: all 500ms ease-in-out;
    }

    .ft-model-3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-111 {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 39.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 34rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 85rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-1111 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ft-con {
    width: 100%;
    margin: 1.7rem auto;
    position: relative;

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-img {
      width: 100%;
      height: 100%;
      background: no-repeat 50% / cover;
      transition: all 500ms ease-in-out;
    }

    .post-text {
      position: absolute;
      bottom: 0.66rem;
      left: 50%;
      transform: translate(-50%);
      width: 85%;
      border-radius: 0.42rem;
      font-size: 1.3rem;
      text-align: center;
      height: 3.9rem;
      line-height: 3.9rem;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      transition: all 500ms ease-in-out;
    }

    .ft-model-3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-111 {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 39.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 34rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 85rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-1111 {
      height: 24rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
        .post-text {
          position: absolute;
          top: 0rem;
          height: 4rem;
          background-color: #d9cebe;
          color: #000;
          width: 100%;
          border-radius: 0rem;
          text-align: left;
          padding-left: 2rem;
          font-size: 1.5rem;
        }
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ft-model-2222 {
      height: 44rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .post-con {
        width: 28rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
        margin: 1rem;
        .post-text {
          width: 100%;
          height: 4rem;
          line-height: 4rem;
          position: absolute;
          bottom: 0px;
          text-align: left;
          padding-left: 2rem;
          background: no-repeat url(../../assets/ftbg.png);
          background-size: cover;
        }
      }

      .post-con:nth-child(1n + 9) {
        display: none;
      }
    }
  }
}
</style>

