<template>
  <div class="web-item">
    <div class="item-title-container">
      <div class="item-title" @click="$parent.onClickTopic(webItem.id)">
        {{ webItem.title }}
      </div>
    </div>
    <!-- <div class="activity-con"> -->

    <div class="reperPic-list">
      <div class="list-con">
        <a class="reperPic-img" v-for="p in content.list" :key="'' + ' ' + p.postId + '_paper'" :href="'post.html?id=' + p.postId" target="blank">
          <div class="reper-img" :style="{ backgroundImage: `url(` + p.imgURL + `)` }"></div>
          <div class="text-title">{{ p.title }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexPFT',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      logined: false,
      webItem: {},
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.list.forEach((i) => {
          if (i.frontPicId) {
            i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
          }
        })

        this.webItem = this.content
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
// .camera-icon {
//   background: no-repeat 50% / contain url(../../assets/camera.png);
// }
@media all and (max-width: 800px) {
  .image-list {
    height: 447px;
    width: 750px;
    .list-con,
    .second {
      height: 50%;
      width: 1198px;
      display: flex;
      flex-wrap: wrap;
      border: 0.1rem solid #ccc;
      box-shadow: 0 0 1rem #ccc;
      margin: 3rem auto;
      a {
        text-decoration: none;
        color: #000;
      }
      .image-img {
        width: 26.5rem;
        height: 17.1rem;
        margin: 2rem 1.5rem;
        position: relative;
        cursor: pointer;
        .yingsai-img {
          background: no-repeat 50% / cover;
          height: 95%;
        }
        .text-title {
          width: 27.6rem;
          height: 2rem;
          line-height: 2rem;
          margin-top: 1rem;
          font-size: 1.3rem;
          top: 18.2rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .web-item {
    width: 120rem !important;
    margin: 0 auto;
    .web-item-more {
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .reperPic-list {
    height: 46rem;
    position: relative;
    overflow: hidden;
    .list-con {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      top: 0rem;
      left: 0rem;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: #000;
      }
      .reperPic-img {
        width: 28rem;
        height: 42.5rem;
        margin-top: 2rem;
        position: relative;
        cursor: pointer;
        .reper-img {
          height: 96%;
          width: 100%;
          background: no-repeat 50% / contain;
        }
        .text-title {
          width: 30rem;
          height: 5rem;
          line-height: 5rem;
          font-size: 1.3rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>

