<template>
  <div class="fts">
    <div class="item-title-container" @click="onCatalogMore(webItem.title)">
      <div class="item-title">
        {{ webItem.title }}
      </div>
    </div>
    <div class="fts-model-m_3s" v-if="webItem.model == 'm_3s'" @mouseenter="stopTimer()" @mouseleave="restartTimer()">
      <div
        v-for="(pic, contentIndex) in webItem.list"
        :key="contentIndex + 'FTS' + pic.id"
        class="pic"
        :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
        :class="{ active: content.picIndex == contentIndex }"
        @click="$parent.onLookPost(pic.postId)"
      ></div>
      <div class="text">
        <div
          v-for="(pic, navIndex) in webItem.list"
          :key="'fts_' + pic.id + '_nav' + '_' + navIndex"
          class="nav-pic"
          :class="{ active: content.picIndex == navIndex }"
          @click="scrollTo(index, navIndex)"
        ></div>
      </div>
    </div>

    <!-- 固定栏目其它 -->
    <div :class="'fts-model-' + webItem.model" v-else>
      <div class="post-con" v-for="post in webItem.list" :key="'fts_' + post.id">
        <template v-if="post.posts[0]">
          <div class="post-img" :style="{ backgroundImage: `url(` + post.posts[0].postImgURL + `)` }" @click="onFixtopicMore(post.id)"></div>
          <div class="post-text">
            <div class="icon"></div>
            <div @click="$parent.onClickTopic(post.id)" class="culture-t">
              {{ post.name }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexFts',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return { webItem: {} }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      setTimeout(() => {
        thiz.content.list.forEach((i) => {
          i.imgURL = RD.fixedTopic().id(i.id).headPic_URL()
          i.posts.forEach((k) => {
            k.postImgURL = RD.pic().id(k.frontPicId).mImage_URL()
            k.videoimgURL = RD.video().id(k.postId).poster_URL()
            k.videoURL = RD.video().id(k.postId).svideo_URL()
            k.posterShow = true
          })
        })
        this.webItem = this.content
      }, 1000)
    },
    // 更多fts
    onCatalogMore(title) {
      let routerUrl = this.$router.resolve({
        path: '/catalog.html',
        query: { title: title },
      })
      window.open(routerUrl.href, '_blank')
    },
    // fixtopic更多的稿件
    onFixtopicMore(topicId) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: topicId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .fts-model-m_22 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    overflow: hidden;
    width: 750px;
    margin: 0 auto;
    .post-con {
      width: 370px;
      height: 325px;

      .post-img {
        height: 286px;
        width: 100%;
        background-size: cover;
        cursor: pointer;
      }

      .post-text {
        font-size: 1.5rem;
        display: flex;

        .icon {
          display: inline-block;
          width: 45px;
          height: 35px;
          margin-left: 137px;
          background: no-repeat url(../../assets/culturetwo.png);
          background-size: cover;
        }

        .culture-t {
          cursor: pointer;
        }

        div {
          height: 42px;
          line-height: 42px;
        }
      }
    }
  }
  .ft-model-m_21 {
    height: 510px;
    position: relative;
    overflow: hidden;
    width: 750px;
    margin: 0 auto;

    .post-con {
      position: absolute;
      overflow: hidden;
    }

    .post-con:nth-child(1) {
      width: 340px;
      height: 250px;
      top: 0;
      left: 0;
    }

    .post-con:nth-child(2) {
      width: 340px;
      height: 250px;
      bottom: 0;
      left: 0;
    }

    .post-con:nth-child(3) {
      width: 850px;
      height: 510px;
      top: 0;
      right: 0;
    }

    .post-con:nth-child(1n + 4) {
      display: none;
    }
  }
  .ft-model-m_111 {
    height: 195px;
    position: relative;
    overflow: hidden;
    width: 750px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    .post-con {
      width: 395px;
      height: 200px;
      position: relative;
      overflow: hidden;
    }

    .post-con:nth-child(1n + 4) {
      display: none;
    }
  }
}
@media all and (min-width: 801px) {
  .post-con {
    cursor: pointer;
  }
  .fts-model-m_22 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;
    .post-con {
      width: 58.3rem;
      height: 32.25rem;

      .post-img {
        height: 28.6rem;
        width: 100%;
        background-size: cover;
        cursor: pointer;
      }

      .post-text {
        font-size: 1.5rem;
        display: flex;

        .icon {
          display: inline-block;
          width: 4.5rem;
          height: 3.5rem;
          margin-left: 22.4rem;
          background: no-repeat url(../../assets/culturetwo.png);
          background-size: cover;
        }

        .culture-t {
          cursor: pointer;
        }

        div {
          height: 4.2rem;
          line-height: 4.2rem;
        }
      }
    }
  }
  .ft-model-m_21 {
    height: 51rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;

    .post-con {
      position: absolute;
      overflow: hidden;
    }

    .post-con:nth-child(1) {
      width: 34rem;
      height: 25rem;
      top: 0;
      left: 0;
    }

    .post-con:nth-child(2) {
      width: 34rem;
      height: 25rem;
      bottom: 0;
      left: 0;
    }

    .post-con:nth-child(3) {
      width: 85rem;
      height: 51rem;
      top: 0;
      right: 0;
    }

    .post-con:nth-child(1n + 4) {
      display: none;
    }
  }
  .ft-model-m_111 {
    height: 19.5rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    .post-con {
      width: 39.5rem;
      height: 20rem;
      position: relative;
      overflow: hidden;
    }

    .post-con:nth-child(1n + 4) {
      display: none;
    }
  }
  .fts-model-m_2222 {
    height: 44rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .post-con {
      width: 28rem;
      height: 20rem;
      position: relative;
      overflow: hidden;
      margin: 1rem;
      .post-img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
      }
      .post-text {
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        position: absolute;
        bottom: 0px;
        text-align: left;
        padding-left: 2rem;
        background: no-repeat url(../../assets/ftbg.png);
        background-size: cover;
        color: #fff;
        font-size: 1.6rem;
      }
    }

    .post-con:nth-child(1n + 9) {
      display: none;
    }
  }
  .fts-model-m_1111 {
    height: 24rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    .post-con {
      flex: 1;
      height: 20rem;
      position: relative;
      overflow: hidden;
      .post-img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
      }
      .post-text {
        position: absolute;
        top: 0rem;
        height: 4rem;
        line-height: 4rem;
        background-color: #d9cebe;
        color: #000;
        width: 100%;
        border-radius: 0rem;
        text-align: left;
        padding-left: 2rem;
        font-size: 1.6rem;
      }
    }

    .post-con:nth-child(1n + 5) {
      display: none;
    }
  }
}
</style>

