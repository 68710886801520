<template>
  <div class="nodes">
    <div class="item-title">
      <div class="item-title-nodes">{{ webItem.title }}</div>
      <div class="item-con">
        <!-- <div><a href="https://zhaojue.chinaphotos.cn/" target="_blank">昭觉分站</a></div> -->
        <!-- <div>昭觉分站</div> -->
      </div>
    </div>
    <div class="nodes-con" @mouseenter="usstopTimer()" @mouseleave="usrestartTimer()">
      <!-- <div class="nodes-con"> -->
      <div class="nodes-swiper">
        <div
          class="nodes-item"
          v-for="(p, litterIndex) in webItem.list"
          :key="p.id + '_pic'"
          ref="swiperpic"
          v-bind:class="{
            transformUp: content.isUp,
          }"
          @click="onLookPic(litterIndex)"
        >
          <!-- <div class="bg-img" :src="p.imgURL" @click="onEnlarge(litterIndex)">
            <a href="" target="_blank"></a>
          </div> -->
          <template v-if="p.link == ''">
            <img class="nodes-img" :src="p.imgURL" />
          </template>
          <template v-if="p.link != ''">
            <a :href="p.link" target="_blank">
              <img class="nodes-img" :src="p.imgURL" />
              <div class="nodes-name">{{ p.name }}</div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var nodestimers = []
import RD from '@/api/RD'

export default {
  name: 'IndexNodes',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      transformDistance: 0, // 移动的距离
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      // console.log('mxnode', this.content)
      // setTimeout(() => {
      this.content.list.forEach((k) => {
        k.imgURL = RD.node().id(k.id).image_URL()
      })
      this.webItem = this.content
      // }, 1000)
      nodestimers = setInterval(() => {
        ;(() => {
          thiz.move()
        })()
      }, 4000)
    },
    move() {
      var thiz = this
      thiz.content.isUp = true
      setTimeout(() => {
        this.$nextTick(function () {
          this.content.isUp = false
          var temp = this.content.list.shift()
          this.content.list.push(temp)
        })
      }, 2500)
    },
    usstopTimer() {
      clearInterval(nodestimers)
    },
    usrestartTimer() {
      if (nodestimers) {
        clearInterval(nodestimers)
      }
      nodestimers = setInterval(() => {
        ;(() => {
          this.move()
        })()
      }, 3000)
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .nodes-con {
    width: 750px;
    margin: 0px auto;

    .nodes-list {
      .nodes-item {
        height: auto;
        .item-name {
          height: 30px;
          line-height: 30px;
          font-size: 1.8rem;
          text-align: center;
          border-radius: 2.5rem;
          border: 1px solid red;
          width: 10rem;
          margin: 0.5rem 0rem;
          background-color: #ebedf1;
          cursor: default;
        }

        .item-con {
          display: flex;
          flex-wrap: wrap;

          div {
            height: 2rem;
            line-height: 2rem;
            font-size: 1.6rem;
            border-radius: 2.5rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            background-color: #ebedf1;
            padding: 1rem;
            border: 1px solid #ccc;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .nodes {
    width: 120rem;
    margin: 2rem auto 3rem;
  }
  .item-title {
    width: 120rem;
    display: flex;
    justify-content: center;
    .item-title-nodes {
      width: 9rem;
      text-align: center;
      margin: 0 auto;
      font-size: 2.2rem;
      border-bottom: 0.5rem solid #cf0000;
      height: 4.5rem;
      padding: 0px 1.5rem;
      margin-top: 2rem;
    }
    .item-con {
      display: flex;
      margin-left: 2rem;
      font-size: 1.6rem;
      div {
        line-height: 7rem;
        // cursor: pointer;
        a {
          display: flex;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .nodes-con {
    width: 120rem;
    margin: 2rem auto;
    overflow: hidden;
    .nodes-swiper {
      display: flex;
      flex-wrap: nowrap;
      width: auto;
      position: relative;
      .nodes-item {
        width: 39.5rem;
        height: 30rem;
        margin-right: 1rem;
        flex-shrink: 0;
        // .bg-img {
        //   width: 100%;
        //   height: 100%;
        //   background: no-repeat 50% / contain;
        // }
        a {
          width: 100%;
          height: 100%;
          position: relative;
          .nodes-img {
            width: 100%;
            height: 100%;
          }
          .nodes-name {
            position: absolute;
            bottom: 0px;
            height: 5rem;
            line-height: 5rem;
            width: 100%;
            font-size: 1.6rem;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
        .ad-img {
          width: 100%;
        }
      }
      .transformUp {
        transform: translateX(-405px);
        transition: all 1000ms ease-in-out;
      }
      // .nodes-content {
      //   display: flex;
      //   flex-wrap: wrap;
      // }
      // .nodes-item {
      //   width: 100%;
      //   height: auto;
      //   display: flex;
      //   flex-wrap: wrap;
      //   .item-name {
      //     height: 3rem;
      //     line-height: 3rem;
      //     font-size: 1.8rem;
      //     text-align: center;
      //     border-radius: 2.5rem;
      //     border: 1px solid red;
      //     width: 10rem;
      //     margin: 0.5rem 0rem;
      //     background-color: #ebedf1;
      //     cursor: default;
      //   }
      //   .item-icon {
      //     width: 3rem;
      //     line-height: 3.2rem;
      //     cursor: pointer;
      //   }
      //   .el-icon-caret-right,
      //   .el-icon-caret-bottom {
      //     font-size: 22px;
      //   }
      // }
      // .item-con {
      //   display: flex;
      //   flex-wrap: wrap;
      //   width: 100%;
      //   div {
      //     height: 2rem;
      //     line-height: 2rem;
      //     font-size: 1.6rem;
      //     border-radius: 2.5rem;
      //     margin-right: 1rem;
      //     margin-bottom: 1rem;
      //     background-color: #ebedf1;
      //     padding: 1rem;
      //     border: 1px solid #ccc;
      //     cursor: pointer;
      //   }
      // }
    }
  }
}
</style>

