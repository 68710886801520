<template>
  <div class="ad">
    <template v-if="webItem.url == ''">
      <img class="ad-img" :src="webItem.imgURL" />
    </template>
    <template v-if="webItem.url != ''">
      <a :href="webItem.url" target="_blank">
        <img class="ad-img" :src="webItem.imgURL" />
      </a>
    </template>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexAd',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return { webItem: {} }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.imgURL = RD.ad().id(this.content.id).image_URL()
        this.webItem = JSON.parse(JSON.stringify(this.content))
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ad {
    width: 750px;
    margin: 20px 0px;
    a {
      width: 100%;

      .ad-img {
        width: 100%;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ad {
    width: 120rem;
    margin: 3rem auto;
    a {
      width: 100%;

      .ad-img {
        width: 100%;
      }
    }
    .ad-img {
      width: 100%;
    }
  }
}
</style>
