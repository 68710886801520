<template>
  <div class="topft">
    <div class="item-title-container">
      <div class="item-title" @click="onClickTopic(webItem.id)">
        {{ webItem.title }}
      </div>
    </div>
    <div class="top-ft">
      <div class="view-three-left" v-if="webItem.list">
        <div
          class="viewthree-left-img"
          :style="{
            backgroundImage: `url(` + webItem.list[0].imgURL + `)`,
          }"
          @click="$parent.onLookPost(webItem.list[0].postId)"
        ></div>
        <div class="viewthree-con-text">{{ webItem.list[0].title }}</div>
      </div>
      <div class="view-three-right">
        <div class="top">
          <div class="title" @mouseenter="announcestopTimer()" @mouseleave="announcestartTimer()">
            <div class="announce-title" :class="{ active: changeFlag }" @click="onAnnounce()">公告</div>
            <div class="gap">/</div>
            <div class="activity-title" :class="{ active: !changeFlag }" @click="onActivity()">活动</div>
          </div>
          <div class="gonggao-con">
            <template v-if="changeFlag">
              <div class="gonggao-pre" v-for="k in gonggaoList" :key="'gonggao_' + k.id">
                <span></span>
                <a :href="'announceDetail.html?id=' + k.id" target="_blank">{{ k.title }}</a>
              </div>
            </template>
            <template v-if="!changeFlag">
              <div class="gonggao-pre" v-for="k in activityList" :key="'gonggao_' + k.id">
                <span></span>
                <a :href="'activityPage.html?code=' + k.id" target="_blank">{{ k.title }}</a>
              </div>
            </template>
          </div>
        </div>
        <div class="company">
          <div class="title">会员单位</div>
          <div class="company-con">
            <div class="item-con" :style="{ top: '-' + transformlargeDistance + 'px' }" @mouseenter="nodesstopTimer()" @mouseleave="nodesrestartTimer()">
              <div class="company-item" v-for="k in nodes.list" :key="'company_' + k.id">
                <div class="item">{{ k.nodeName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="title"><a href="local.html">地方图库</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var compnaytimers = []
var changerTimers = []
import RD from '@/api/RD'

export default {
  name: 'IndextopFt',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    gonggaoList: {
      type: Array,
      default: function () {
        return {}
      },
    },
    activityList: {
      type: Array,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      changeFlag: 'gongg', // 公告和活动的切换
      transformlargeDistance: 0, // 轮播的移动距离
      nodesIndex: 0, // 移动的index
      nodes: {
        nodeIndex: 0,
        list: [
          { id: 47, nodeName: '通州区融媒体中心', link: '' },
          { id: 48, nodeName: '启东市融媒体中心', link: '' },
          { id: 49, nodeName: '海安市融媒体中心', link: '' },
          { id: 50, nodeName: '灌云县融媒体中心', link: '' },
          { id: 51, nodeName: '金湖县融媒体中心', link: '' },
          { id: 52, nodeName: '盱眙县融媒体中心', link: '' },
          { id: 53, nodeName: '武进区融媒体中心', link: '' },
          { id: 54, nodeName: '东台市融媒体中心', link: '' },
          { id: 55, nodeName: '靖江市融媒体中心', link: '' },
          { id: 56, nodeName: '江都区融媒体中心', link: '' },
          { id: 57, nodeName: '高邮市融媒体中心', link: '' },
          { id: 58, nodeName: '丹阳市融媒体中心', link: '' },
          { id: 1, nodeName: '余杭区融媒体中心', link: '' },
          { id: 2, nodeName: '萧山日报社', link: '' },
          { id: 3, nodeName: '桐庐融媒体中心', link: '' },
          { id: 4, nodeName: '千岛湖融媒体中心', link: '' },
          { id: 5, nodeName: '鄞州区融媒体中心', link: '' },
          { id: 6, nodeName: '海曙区融媒体中心', link: '' },
          { id: 7, nodeName: '镇海区融媒体中心', link: '' },
          { id: 8, nodeName: '奉化区融媒体中心', link: '' },
          { id: 9, nodeName: '余姚市融媒体中心', link: '' },
          { id: 10, nodeName: '慈溪市融媒体中心', link: '' },
          { id: 11, nodeName: '宁海传媒集团', link: '' },
          { id: 12, nodeName: '象山县传媒中心', link: '' },
          { id: 13, nodeName: '瑞安市融媒体中心', link: '' },
          { id: 14, nodeName: '乐清融媒体中心', link: '' },
          { id: 15, nodeName: '瓯海区融媒体中心', link: '' },
          { id: 16, nodeName: '龙湾区融媒体中心', link: '' },
          { id: 17, nodeName: '玉环市传媒中心', link: '' },
          { id: 18, nodeName: '文成县融媒体中心', link: '' },
          { id: 19, nodeName: '柯桥区融媒体中心', link: '' },
          { id: 20, nodeName: '上虞区融媒体中心', link: '' },
          { id: 21, nodeName: '诸暨市融媒体中心', link: '' },
          { id: 22, nodeName: '嵊州市融媒体中心', link: '' },
          { id: 23, nodeName: '长兴传媒集团', link: '' },
          { id: 24, nodeName: '海宁市融媒体中心', link: '' },
          { id: 25, nodeName: '义乌市融媒体中心', link: '' },
          { id: 26, nodeName: '东阳市融媒体中心', link: '' },
          { id: 27, nodeName: '兰溪市融媒体中心', link: '' },
          { id: 28, nodeName: '永康市融媒体中心', link: '' },
          { id: 29, nodeName: '衢江区融媒体中心', link: '' },
          { id: 30, nodeName: '温岭市融媒体中心', link: '' },
          { id: 31, nodeName: '临海市融媒体中心', link: '' },
          { id: 32, nodeName: '青田融媒体中心', link: '' },
          { id: 33, nodeName: '龙泉市融媒体中心', link: '' },
          { id: 34, nodeName: '定海区融媒体中心', link: '' },
          { id: 35, nodeName: '普陀区融媒体中心', link: '' },
          { id: 36, nodeName: '通州区融媒体中心', link: '' },
          { id: 37, nodeName: '闵行区融媒体中心', link: '' },
          { id: 38, nodeName: '松江区融媒体中心', link: '' },
          { id: 39, nodeName: '宝山区融媒体中心', link: '' },
          { id: 40, nodeName: '宜兴市融媒体中心', link: '' },
          { id: 41, nodeName: '江阴市融媒体中心', link: '' },
          { id: 42, nodeName: '吴江区融媒体中心', link: '' },
          { id: 43, nodeName: '常熟市融媒体中心', link: '' },
          { id: 44, nodeName: '张家港市融媒体中心', link: '' },
          { id: 45, nodeName: '昆山市融媒体中心', link: '' },
          { id: 46, nodeName: '海门区融媒体中心', link: '' },
          { id: 59, nodeName: '石狮市融媒体中心', link: '' },
          { id: 60, nodeName: '晋江经济报社', link: '' },
          { id: 61, nodeName: '修水县融媒体中心', link: '' },
          { id: 62, nodeName: '贵溪市融媒体中心', link: '' },
          { id: 63, nodeName: '东乡区融媒体中心', link: '' },
          { id: 64, nodeName: '章丘区融媒体中心', link: '' },
          { id: 65, nodeName: '即墨区融媒体中心', link: '' },
          { id: 66, nodeName: '荣成市融媒体中心', link: '' },
          { id: 67, nodeName: '寿光市融媒体中心', link: '' },
          { id: 68, nodeName: '诸城市融媒体中心', link: '' },
          { id: 69, nodeName: '安丘市融媒体中心', link: '' },
          { id: 70, nodeName: '滕州市融媒体中心', link: '' },
          { id: 71, nodeName: '邹城市融媒体中心', link: '' },
          { id: 72, nodeName: '增城区融媒体中心', link: '' },
          { id: 73, nodeName: '龙岗区融媒体中心', link: '' },
          { id: 74, nodeName: '宝安区融媒体中心', link: '' },
          { id: 75, nodeName: '顺德区融媒体中心', link: '' },
          { id: 76, nodeName: '太谷区融媒体中心', link: '' },
          { id: 77, nodeName: '汝州市融媒体中心', link: '' },
          { id: 78, nodeName: '浏阳市融媒体中心', link: '' },
          { id: 79, nodeName: '宁乡市融媒体中心', link: '' },
          { id: 80, nodeName: '南川区融媒体中心', link: '' },
          { id: 81, nodeName: '綦江区融媒体中心', link: '' },
          { id: 82, nodeName: '忠县融媒体中心', link: '' },
          { id: 83, nodeName: '成都市双流区融媒体中心', link: '' },
          { id: 84, nodeName: '成都市新都区融媒体中心', link: '' },
          { id: 85, nodeName: '成都市成华区融媒体中心', link: '' },
          { id: 86, nodeName: '成都市龙泉驿区融媒体中心', link: '' },
          { id: 87, nodeName: '都江堰市融媒体中心', link: '' },
          { id: 88, nodeName: '兴文县融（全）媒体中心', link: '' },
          { id: 89, nodeName: '昭觉县融媒体中心', link: '' },
          { id: 90, nodeName: '玉屏侗族自治县融媒体中心', link: '' },
          { id: 91, nodeName: '新疆生产建设兵团第四师可克达拉市融媒体中心', link: '' },
        ],
      },
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      setTimeout(() => {
        if (this.content.model == '3s') {
          this.content.list = this.content.list.splice(0, 5)
        }
        this.content.list.forEach((i) => {
          if (i.postId) {
            i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
          }
        })
        this.webItem = this.content
        thiz.nodes.list.forEach((m) => {
          m.isUp = false
        })
        compnaytimers = setInterval(() => {
          ;(() => {
            thiz.move()
          })()
        }, 3000)
        changerTimers = setInterval(() => {
          ;(() => {
            thiz.change()
          })()
        }, 3000)
      }, 1000)
    },
    change() {
      var thiz = this
      thiz.changeFlag = !thiz.changeFlag
    },
    move() {
      var thiz = this
      thiz.nodesIndex++
      thiz.transformlargeDistance = thiz.nodesIndex * 150
      if (thiz.nodesIndex >= 7) {
        thiz.nodesIndex = 0
        thiz.transformlargeDistance = 0
      }
    },
    // 切换公告和活动
    onAnnounce() {
      let routerUrl = this.$router.resolve({
        path: '/advertisement.html',
        query: { type: 1 },
      })
      window.open(routerUrl.href, '_blank')
    },
    onActivity() {
      let routerUrl = this.$router.resolve({
        path: '/activity.html',
        query: {},
      })
      window.open(routerUrl.href, '_blank')
    },
    nodesstopTimer() {
      clearInterval(compnaytimers)
    },
    nodesrestartTimer() {
      if (compnaytimers) {
        clearInterval(compnaytimers)
      }
      compnaytimers = setInterval(() => {
        ;(() => {
          this.move()
        })()
      }, 3000)
    },
    announcestopTimer() {
      clearInterval(changerTimers)
    },
    announcestartTimer() {
      const thiz = this
      changerTimers = setInterval(() => {
        ;(() => {
          thiz.change()
        })()
      }, 3000)
    },
    // topft栏目点击
    onClickTopic(ftId) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: ftId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ad {
    width: 750px;
    margin: 20px auto;

    .ad-con {
      height: auto;
      width: 100%;

      a {
        width: 100%;

        .ad-detail {
          width: 100%;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .top-ft {
    height: 41.6rem;
    position: relative;
    overflow: hidden;
    width: 120rem;
    margin: 0 auto;
    display: flex;

    .view-three-left {
      width: 78.7rem;
      height: 100%;
      margin-right: 1.7rem;
      position: relative;
      overflow: hidden;

      .viewthree-left-img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }

      .viewthree-con-text {
        position: absolute;
        bottom: 0px;
        transition: all 500ms ease-in-out;
        width: 100%;
        color: #fff;
        font-size: 1.3rem;
        height: 2.5rem;
        line-height: 2.5rem;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .view-three-left:hover {
      .viewthree-left-img {
        transform: scale(1.05);
      }

      .viewthree-con-text {
        height: 3.9rem;
        font-size: 1.4rem;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    .view-three-right {
      width: 41.6rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        height: 4rem;
        background-color: #dfdde0;
        font-size: 1.8rem;
        line-height: 4rem;
        font-weight: bold;
        letter-spacing: 5px;
        display: flex;
        justify-content: center;
        .announce-title,
        .activity-title {
          flex: 1;
          cursor: pointer;
        }
        .active {
          border-bottom: 2px solid red;
        }
        .gap {
          margin: 0px 20px;
        }
      }
      .top {
        width: 100%;
        margin-bottom: 1.7rem;
        position: relative;
        overflow: hidden;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: 17rem;
        .gonggao-con {
          width: 41.1rem;
          flex-shrink: 0;
          overflow: hidden;
          margin-left: 0.5rem;
          .gonggao-pre {
            text-align: left;
            height: 2.7rem;
            font-size: 1.6rem;
            cursor: pointer;
            position: relative;
            span {
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #000;
              position: absolute;
              top: 10px;
              left: 0px;
            }
            a {
              display: inline-block;
              width: 96%;
              height: 100%;
              margin-left: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .tuku-con {
          display: flex;
          flex-wrap: wrap;
          width: 32rem;
          margin: 0 auto;

          .tuku-pre {
            width: 8rem;
            height: 4rem;
            line-height: 4rem;
            cursor: pointer;
          }
        }

        .viewthree-right-text {
          position: absolute;
          bottom: 0px;
          width: 100%;
          color: #fff;
          transition: all 500ms ease-in-out;
          font-size: 1.3rem;
          height: 2.5rem;
          line-height: 2.5rem;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
      .bottom {
        height: 4rem;
      }

      .company {
        height: 19rem;
        .title {
          cursor: pointer;
        }
        .company-con {
          overflow: hidden;
          height: 15rem;
          position: relative;
          .item-con {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            left: 0px;
            transition: all 1000ms ease-in-out;
            .company-item {
              font-size: 1.5rem;
              margin-right: 1rem;
              height: 3rem;
              line-height: 3rem;
            }
          }
        }
      }

      .viewthree-right:hover {
        .viewthree-right-img {
          transform: scale(1.05);
        }

        .viewthree-right-text {
          height: 3.9rem;
          font-size: 1.4rem;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}
</style>

