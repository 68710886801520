<template>
  <div class="ads">
    <div class="ads-con" @mouseenter="adsstopTimer()" @mouseleave="adsrestartTimer()">
      <div class="ads-list">
        <div
          class="item"
          v-for="k in webItem.list"
          :key="'ads_' + k.id"
          v-bind:class="{
            transformUp: content.isUp,
          }"
        >
          <a :href="k.link" target="_blank">
            <img class="ad-detail" :src="k.imgurl" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var adstimers = []
import RD from '@/api/RD'

export default {
  name: 'IndexAds',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return { webItem: {} }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      setTimeout(() => {
        this.content.list.forEach((k) => {
          k.imgurl = RD.ad().id(k.id).image_URL()
        })
        // this.content.imgurl = RD.ad().id(this.content.id).image_URL()
        this.webItem = this.content
      }, 1000)
      adstimers = setInterval(() => {
        ;(() => {
          thiz.move()
        })()
      }, 3000)
    },
    move() {
      var thiz = this
      thiz.content.isUp = true
      setTimeout(() => {
        this.$nextTick(function () {
          this.content.isUp = false
          var temp = this.content.list.shift()
          this.content.list.push(temp)
        })
      }, 2500)
    },
    adsstopTimer() {
      clearInterval(adstimers)
    },
    adsrestartTimer() {
      if (adstimers) {
        clearInterval(adstimers)
      }
      adstimers = setInterval(() => {
        ;(() => {
          this.move()
        })()
      }, 3000)
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ad {
    width: 750px;
    margin: 20px auto;

    .ad-con {
      height: auto;
      width: 100%;

      a {
        width: 100%;

        .ad-detail {
          width: 100%;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ads {
    width: 120rem;
    margin: 20px auto;

    .ads-con {
      height: auto;
      width: 100%;
      .ads-list {
        overflow: hidden;
        height: 15.3rem;
        .item {
          height: 15.3rem;
        }
        .transformUp {
          transform: translateY(-153px);
          transition: all 1000ms ease-in-out;
        }
      }
      a {
        width: 100%;

        .ad-detail {
          width: 100%;
        }
      }
    }
  }
}
</style>

