<template>
  <div class="all">
    <Nav class="nav" :front="true"></Nav>
    <div class="present minheight">
      <template v-for="(content, index) in contents">
        <!-- 轮播 -->
        <div :key="content.type + '_' + index" v-if="content.type == 'TOP_SHOW'" class="web-item front-show">
          <TOPSHOW :content="content" :logined="logined"></TOPSHOW>
        </div>
        <!-- 顶部栏目-->
        <div :key="content.type + '_' + index" v-if="content.type == 'TOP_FT'" class="web-item">
          <TOPFT :content="content" :gonggaoList="gonggaoList" :activityList="activityList" :logined="logined"></TOPFT>
        </div>
        <!-- FTV 视频栏目  -->
        <div class="web-item" :key="content.type + '_' + index" v-if="content.type == 'FTV'">
          <FTV :content="content" :logined="logined"></FTV>
        </div>
        <!-- 固定栏目 -->
        <div class="web-item ft-con" :key="content.type + '_' + index" v-if="content.type == 'FT'">
          <FT :content="content" :logined="logined"></FT>
        </div>

        <!-- FTS 新闻图片 -->
        <div class="web-item fts-con" :key="content.type + '_' + index" v-if="content.type == 'FTS'">
          <FTS :content="content" :logined="logined"></FTS>
        </div>

        <!-- 最新图片 -->
        <div class="np web-item" :key="content.type + '_' + index" v-if="content.type == 'NP'">
          <NP :content="content" :logined="logined"></NP>
        </div>

        <!-- 城市图库 -->
        <div class="local" :key="content.type + '_' + index" v-if="content.type == 'LC'">
          <LC :content="content" :logined="logined"></LC>
        </div>

        <!-- 摄影活动 -->
        <div class="activity" :key="content.type + '_' + index" v-if="content.type == 'AT'">
          <AT :content="content" :userData="userData"></AT>
        </div>

        <!-- 签约摄影师 -->
        <div class="userShow" :key="content.type + '_' + index" v-if="content.type == 'US'">
          <US :content="content" :logined="logined"></US>
        </div>

        <!-- 广告 -->
        <div class="ad" :key="content.type + '_' + index" v-if="content.type == 'AD'">
          <AD :content="content" :logined="logined"></AD>
        </div>

        <!-- 广告组 -->
        <div class="ads web-item" :key="content.type + '_' + index" v-if="content.type == 'ADS'">
          <ADS :content="content" :logined="logined"></ADS>
        </div>

        <!-- 见报图片 -->
        <div class="reperPic" :key="content.type + '_' + index" v-if="content.type == 'PFT'">
          <PFT :content="content" :logined="logined"></PFT>
        </div>

        <!-- IFarme -->
        <div class="ifame-container" :key="content.type + '_' + index" v-if="content.type == 'IF'">
          <IF :content="content" :logined="logined"></IF>
        </div>

        <!-- 分站入口 -->
        <div class="web-item" :key="content.type + '_' + index" v-if="content.type == 'NODES'">
          <NODES :content="content" :logined="logined"></NODES>
        </div>

        <!-- 特殊类型 -->
        <div class="web-item" :key="content.type + '_' + index" v-if="content.type == 'SPE'">
          <SPE :content="content" :logined="logined"></SPE>
        </div>

        <!-- 友情链接 -->
        <div class="link" :key="content.type + '_' + index" v-if="content.type == 'BOTTOM_SHOW'">
          <div class="link-title">
            <div class="link-name">友情链接</div>
            <div class="clip"></div>
          </div>
          <div class="link-con">
            <div class="link-list">
              <div
                class="link-detail"
                v-for="p in content.list"
                :key="'link-detail' + p.id + '_' + index"
                v-bind:class="{
                  transformLeft: content.isleft,
                }"
              >
                <a :href="p.link" target="_blank">{{ p.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <Bottom></Bottom>
  </div>
</template>
<script>
var timers = []
var ustimers = []
var localTimer = []
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
import AD from '@/views/index/ad.vue'
import ADS from '@/views/index/ads.vue'
import US from '@/views/index/us.vue'
import LINK from '@/views/index/link.vue'
import IF from '@/views/index/ifame.vue'
import AT from '@/views/index/at.vue'
import NP from '@/views/index/np.vue'
import TOPSHOW from '@/views/index/topShow.vue'
import TOPFT from '@/views/index/topFt.vue'
import FTV from '@/views/index/ftv.vue'
import FT from '@/views/index/ft.vue'
import LC from '@/views/index/lc.vue'
import NODES from '@/views/index/nodes.vue'
import SPE from '@/views/index/spe.vue'
import PFT from '@/views/index/pft.vue'
import FTS from '@/views/index/fts.vue'

export default {
  name: 'Index',
  components: { Nav, Bottom, AD, US, LINK, IF, AT, NP, TOPSHOW, TOPFT, FTV, FT, LC, NODES, ADS, PFT, FTS, SPE },
  data() {
    return {
      logined: false,
      clickIndex: -1,
      showBg: false, // 公告背景图的显示与隐藏
      isTransform: false, // 点击移动一个
      isTransformRight: false,
      picIndex: 0,
      contents: [], // main里面的所有数据
      lcIndex: 0,
      tukuList: [], // 图库的列表
      gonggaoList: [],
      activityList: [],
      userData: [],
      nodesSubShow: false, // 分站的sub展示
    }
  },
  created() {
    this.reload()
  },
  mounted() {},
  methods: {
    selectGapByType(type) {
      switch (type) {
        case 'TOP_SHOW':
          return 3000
        default:
          return 3000
      }
    },
    reload() {
      var thiz = this
      // RD.node()
      //   .actives()
      //   .then(() => {})
      RD.pure()
        .user()
        .sync()
        .then(() => {
          this.logined = true
        })
      RD.addr()
        .id(10)
        .downAddress()
        .then((data) => {
          thiz.tukuList = data
        })
      RD.notice()
        .allByGroup(1)
        .then((type) => {
          thiz.gonggaoList = type
        })
      // activityList
      RD.activityTopic()
        .forShow()
        .then((data) => {
          thiz.activityList = data
        })
      RD.index()
        .main(5)
        .then((data) => {
          data.contents.forEach((content, contentIndex) => {
            content.picIndex = 0
            content.localIndex = 0
            content.isTransform = false
            content.isTransformRight = false
            content.isUp = false
            content.isleft = false
            switch (content.type) {
              case 'TOP_SHOW':
                break
              case 'FT':
              case 'TOP_FT':
                break
              case 'FTS':
                break
              case 'PFT':
                content.list.forEach((i) => {
                  if (i.frontPicId) {
                    i.imgURL = RD.pic().id(i.frontPicId).mImage_URL()
                  }
                })
                // timers[contentIndex] = setInterval(() => {
                //   this.next(contentIndex)
                // }, thiz.selectGapByType(content.type))

                break
              case 'AD':
                break
              case 'IF':
                break
              case 'AT':
                break
              case 'ANNOUNCE':
                break
              case 'BOTTOM_SHOW':
                break
              case 'LC':
                break
              case 'US':
                break
              case 'FTV':
                break
              case 'NP':
                break
            }
          })
          thiz.contents = data.contents
        })
    },
    // ftv,
    onClickTopic(ftId) {
      let routerUrl = this.$router.resolve({
        path: '/fixTopic.html',
        query: { id: ftId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // ft模块,ftv
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    //点击分站,nodes模块
    onNodeClick(link) {
      // this.$message('分站尚未建立')
      console.log('mxx')
      this.$rtm.message.notify('分站尚未建立')
    },
    // 摄影师注册页面, at模块
    onGoregister(type) {
      let routerUrl = this.$router.resolve({
        path: '/register.html',
        query: { id: type },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
.all {
  width: 100%;
  background-color: #f8f8f8;
  overflow: hidden;
  // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  // -webkit-filter: grayscale(100%);
}
.web-item {
  width: 100%;
}
::v-deep .web-item {
  display: flex;
  flex-direction: column;

  .item-title-container {
    position: relative;

    .item-title {
      margin: 0 auto;
      cursor: pointer;
      display: inline-block;
    }
  }
}
@media all and (min-width: 801px) {
  ::v-deep .web-item {
    .item-title-container {
      height: 10rem;
      width: 120rem;
      margin: 1.7rem auto;
      line-height: 10rem;

      .item-title {
        font-size: 2.2rem;
        border-bottom: 0.5rem solid #cf0000;
        height: 7.5rem;
        padding: 0px 1.5rem;
      }
    }
  }
}
@media all and (max-width: 800px) {
  ::v-deep .web-item {
    .item-title-container {
      height: 62px;
      width: 750px;
      margin: 10px auto;
      line-height: 62px;

      .item-title {
        font-size: 14px;
        border-bottom: 3px solid #cf0000;
        height: 45px;
        padding: 0px 10px;
      }
    }
  }
}
</style>
<style lang="less">
</style>
