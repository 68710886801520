<template>
  <div class="web-item">
    <div class="item-title-container">
      <div class="item-title">
        {{ webItem.title }}
      </div>
    </div>
    <iframe :src="content.url" frameborder="0" width="100%" :height="content.height" scrolling="no"></iframe>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexIfame',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return { webItem: {} }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.imgurl = RD.ad().id(this.content.id).image_URL()
        this.webItem = this.content
      }, 1000)
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ifame-container {
    height: 291px;
    background-color: #f1f1f1;
    margin-bottom: 1.7rem;
    width: 750px;
    margin: 0 auto;

    .ifame-detail {
      width: 100rem;
      margin: 0 auto;
    }
  }
}
@media all and (min-width: 801px) {
  .ifame-container {
    height: auto;
    background-color: #f1f1f1;
    margin: 20px 0px;
    .ifame-detail {
      width: 100%;
      margin: 0 auto;
      .ifame-title {
        position: relative;
        height: 10rem;
        width: 120rem;
        margin: 1.7rem auto;
        line-height: 10rem;
        .item-title {
          margin: 0 auto;
          font-size: 2.2rem;
          cursor: pointer;
          display: inline-block;
          border-bottom: 0.5rem solid #cf0000;
          height: 7.5rem;
          padding: 0px 1.5rem;
        }
      }
    }
  }
}
</style>

