<template>
  <div class="nodes">
    <div class="item-title">
      <div class="item-title-nodes">
        <a href="/localNodes.html" target="_blank">{{ webItem.title }}</a>
      </div>
      <div class="item-con">
        <!-- <div><a href="https://zhaojue.chinaphotos.cn/" target="_blank">昭觉分站</a></div> -->
        <!-- <div>昭觉分站</div> -->
      </div>
    </div>
    <div class="nodes-con">
      <div class="nodes-list">
        <div class="nodes-content" v-for="(node, nodeIndex) in nodes" :key="'node_' + nodeIndex">
          <div class="nodes-item">
            <div class="item-name">{{ node.groupName }}</div>
            <div class="item-icon el-icon-caret-right" v-if="!node.nodesSubShow" @click="onNodesSubDown(nodeIndex)"></div>
            <div class="item-icon el-icon-caret-bottom" v-else @click="onNodesSubUp(nodeIndex)"></div>
          </div>
          <div class="item-con" v-if="node.nodesSubShow">
            <div class="item" v-for="(nodeSub, nodeSubIndex) in node.subs" :key="'node_' + nodeIndex + '_' + nodeSubIndex">
              <a v-if="nodeSub.dis" :href="nodeSub.link" target="_blank">{{ nodeSub.nodeName }}</a>
              <div v-else @click="onNoopen">{{ nodeSub.nodeName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexNodes',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      nodes: [
        {
          groupName: '北京',
          nodesSubShow: false,
          subs: [
            {
              nodeName: '通州区融媒体中心',
              link: '',
            },
          ],
        },
        {
          groupName: '上海',
          nodesSubShow: false,
          subs: [
            {
              nodeName: '闵行区融媒体中心',
              link: '',
            },
            {
              nodeName: '松江区融媒体中心',
              link: '',
            },
            {
              nodeName: '宝山区融媒体中心',
              link: '',
            },
          ],
        },
        {
          groupName: '江苏',
          nodesSubShow: false,
          subs: [
            { nodeName: '宜兴市融媒体中心', link: '' },
            { nodeName: '江阴市融媒体中心', link: '' },
            { nodeName: '吴江区融媒体中心', link: '' },
            { nodeName: '常熟市融媒体中心', link: '' },
            { nodeName: '张家港市融媒体中心', link: '' },
            { nodeName: '昆山市融媒体中心', link: '' },
            { nodeName: '海门区融媒体中心', link: '' },
            { nodeName: '通州区融媒体中心', link: '' },
            { nodeName: '启东市融媒体中心', link: '' },
            { nodeName: '海安市融媒体中心', link: '' },
            { nodeName: '灌云县融媒体中心', link: '' },
            { nodeName: '金湖县融媒体中心', link: '' },
            { nodeName: '盱眙县融媒体中心', link: '' },
            { nodeName: '武进区融媒体中心', link: '' },
            { nodeName: '东台市融媒体中心', link: '' },
            { nodeName: '靖江市融媒体中心', link: '' },
            { nodeName: '江都区融媒体中心', link: '' },
            { nodeName: '高邮市融媒体中心', link: '' },
            { nodeName: '丹阳市融媒体中心', link: '' },
          ],
        },
        {
          groupName: '浙江',
          nodesSubShow: false,
          subs: [
            { nodeName: '余杭区融媒体中心', link: '' },
            { nodeName: '萧山日报社', link: '' },
            { nodeName: '桐庐融媒体中心', link: '' },
            { nodeName: '千岛湖融媒体中心', link: '' },
            { nodeName: '鄞州区融媒体中心', link: '' },
            { nodeName: '海曙区融媒体中心', link: '' },
            { nodeName: '镇海区融媒体中心', link: '' },
            { nodeName: '奉化区融媒体中心', link: '' },
            { nodeName: '余姚市融媒体中心', link: '' },
            { nodeName: '慈溪市融媒体中心', link: '' },
            { nodeName: '宁海传媒集团', link: '' },
            { nodeName: '象山县传媒中心', link: '' },
            { nodeName: '瑞安市融媒体中心', link: '' },
            { nodeName: '乐清融媒体中心', link: '' },
            { nodeName: '瓯海区融媒体中心', link: '' },
            { nodeName: '龙湾区融媒体中心', link: '' },
            { nodeName: '玉环市传媒中心', link: '' },
            { nodeName: '文成县融媒体中心', link: '' },
            { nodeName: '柯桥区融媒体中心', link: '' },
            { nodeName: '上虞区融媒体中心', link: '' },
            { nodeName: '诸暨市融媒体中心', link: '' },
            { nodeName: '嵊州市融媒体中心', link: '' },
            { nodeName: '长兴传媒集团', link: '' },
            { nodeName: '海宁市融媒体中心', link: '' },
            { nodeName: '义乌市融媒体中心', link: '' },
            { nodeName: '东阳市融媒体中心', link: '' },
            { nodeName: '兰溪市融媒体中心', link: '' },
            { nodeName: '永康市融媒体中心', link: '' },
            { nodeName: '衢江区融媒体中心', link: '' },
            { nodeName: '温岭市融媒体中心', link: '' },
            { nodeName: '临海市融媒体中心', link: '' },
            { nodeName: '青田融媒体中心', link: '' },
            { nodeName: '龙泉市融媒体中心', link: '' },
            { nodeName: '定海区融媒体中心', link: '' },
            { nodeName: '普陀区融媒体中心', link: '' },
          ],
        },
        {
          groupName: '福建',
          nodesSubShow: false,
          subs: [
            { nodeName: '石狮市融媒体中心', link: '' },
            { nodeName: '晋江经济报社', link: '' },
          ],
        },
        {
          groupName: '江西',
          nodesSubShow: false,
          subs: [
            { nodeName: '修水县融媒体中心', link: '' },
            { nodeName: '贵溪市融媒体中心', link: '' },
            { nodeName: '东乡区融媒体中心', link: '' },
          ],
        },
        {
          groupName: '山东',
          nodesSubShow: false,
          subs: [
            { nodeName: '章丘区融媒体中心', link: '' },
            { nodeName: '即墨区融媒体中心', link: '' },
            { nodeName: '荣成市融媒体中心', link: '' },
            { nodeName: '寿光市融媒体中心', link: '' },
            { nodeName: '诸城市融媒体中心', link: '' },
            { nodeName: '安丘市融媒体中心', link: '' },
            { nodeName: '滕州市融媒体中心', link: '' },
            { nodeName: '邹城市融媒体中心', link: '' },
            { nodeName: '新泰市融媒体中心', link: '' },
          ],
        },
        {
          groupName: '广东',
          nodesSubShow: false,
          subs: [
            { nodeName: '增城区融媒体中心', link: '' },
            { nodeName: '龙岗区融媒体中心', link: '' },
            { nodeName: '宝安区融媒体中心', link: '' },
            { nodeName: '顺德区融媒体中心', link: '' },
          ],
        },
        {
          groupName: '山西',
          nodesSubShow: false,
          subs: [
            { nodeName: '太谷区融媒体中心', link: '' },
            { nodeName: '平顺县融媒体中心', link: '' },
          ],
        },
        {
          groupName: '河南',
          nodesSubShow: false,
          subs: [{ nodeName: '汝州市融媒体中心', link: '' }],
        },
        {
          groupName: '湖北',
          nodesSubShow: false,
          subs: [{ nodeName: '红安县融媒体中心', link: '' }],
        },
        {
          groupName: '湖南',
          nodesSubShow: false,
          subs: [
            { nodeName: '浏阳市融媒体中心', link: '' },
            { nodeName: '宁乡市融媒体中心', link: '' },
          ],
        },
        {
          groupName: '重庆',
          nodesSubShow: false,
          subs: [
            { nodeName: '南川区融媒体中心', link: '' },
            { nodeName: '綦江区融媒体中心', link: '' },
            { nodeName: '忠县融媒体中心', link: '' },
          ],
        },
        {
          groupName: '四川',
          nodesSubShow: false,
          subs: [
            { nodeName: '成都市双流区融媒体中心', link: '' },
            { nodeName: '成都市新都区融媒体中心', link: '' },
            { nodeName: '成都市成华区融媒体中心', link: '' },
            { nodeName: '成都市龙泉驿区融媒体中心', link: '' },
            { nodeName: '都江堰市融媒体中心', link: '' },
            { nodeName: '兴文县融（全）媒体中心', link: '' },
            { nodeName: '昭觉县融媒体中心', link: 'https://zhaojue.chinaphotos.cn/', dis: true },
          ],
        },
        {
          groupName: '贵州',
          nodesSubShow: false,
          subs: [{ nodeName: '玉屏侗族自治县融媒体中心', link: '' }],
        },
        {
          groupName: '新疆',
          nodesSubShow: false,
          subs: [{ nodeName: '新疆生产建设兵团第四师可克达拉市融媒体中心', link: '' }],
        },
      ],
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.webItem = this.content
      }, 1000)
    },
    // 各区分站的展开，,nodes模块
    onNodesSubDown(index) {
      var thiz = this
      thiz.nodes[index].nodesSubShow = true
    },
    // 收起，,nodes模块
    onNodesSubUp(index) {
      var thiz = this
      thiz.nodes[index].nodesSubShow = false
    },
    onNoopen() {
      this.$notify.info({
        title: '提示',
        message: '当前分站暂未开放',
      })
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .nodes-con {
    width: 750px;
    margin: 0px auto;

    .nodes-list {
      .nodes-item {
        height: auto;
        .item-name {
          height: 30px;
          line-height: 30px;
          font-size: 1.8rem;
          text-align: center;
          border-radius: 2.5rem;
          border: 1px solid red;
          width: 10rem;
          margin: 0.5rem 0rem;
          background-color: #ebedf1;
          cursor: default;
        }

        .item-con {
          display: flex;
          flex-wrap: wrap;

          .item {
            height: 2rem;
            line-height: 2rem;
            font-size: 1.6rem;
            border-radius: 2.5rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            background-color: #ebedf1;
            padding: 1rem;
            border: 1px solid #ccc;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .nodes {
    width: 120rem;
    margin: 2rem auto 5rem;
  }
  .item-title {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    .item-title-nodes {
      text-align: left;
      font-size: 2.2rem;
      border-bottom: 0.5rem solid #cf0000;
      height: 4.5rem;
      padding: 0px 1.5rem;
      margin-top: 2rem;
      margin-left: 5.5rem;
      cursor: pointer;
    }
    .item-con {
      display: flex;
      margin-left: 2rem;
      font-size: 1.6rem;
      .item {
        line-height: 7rem;
        // cursor: pointer;
        a {
          display: flex;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .nodes-con {
    width: 120rem;
    margin: 2rem auto;
    .nodes-list {
      display: flex;
      flex-wrap: wrap;
      .nodes-content {
        display: flex;
        flex-wrap: wrap;
      }
      .nodes-item {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        .item-name {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.8rem;
          text-align: center;
          border-radius: 2.5rem;
          border: 1px solid red;
          width: 10rem;
          margin: 0.5rem 0rem;
          background-color: #ebedf1;
          cursor: default;
        }
        .item-icon {
          width: 3rem;
          line-height: 3.2rem;
          cursor: pointer;
        }
        .el-icon-caret-right,
        .el-icon-caret-bottom {
          font-size: 22px;
        }
      }
      .item-con {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .item {
          height: 2rem;
          line-height: 2rem;
          font-size: 1.6rem;
          border-radius: 2.5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          background-color: #ebedf1;
          padding: 1rem;
          border: 1px solid #ccc;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

