<template>
  <div class="image">
    <div class="web-item">
      <!-- <div class="web-item-header">
        <div class="camera-icon web-item-icon"></div>
        <div class="web-item-title">{{ webItem.title }}</div>
        <div class="web-item-more">
          <a href="/activity.html" target="_blank">更多>>></a>
        </div>
        <div class="web-item-header-gap"></div>
      </div> -->
      <!-- <div class="activity-con"> -->
      <div class="item-title-container">
        <div class="item-title">
          <a href="/activity.html" target="_blank">{{ webItem.title }}</a>
        </div>
      </div>

      <div class="image-list">
        <div class="list-con">
          <div class="image-img" v-for="p in ATFourList" :key="'' + ' ' + 'at_' + p.id">
            <!-- <template v-if="p.id >= 140"> -->
            <template>
              <a :href="'activityPage.html?code=' + p.id" target="blank">
                <div class="yingsai-img" :style="{ backgroundImage: `url(` + p.imgURL + `)` }"></div>
                <div class="text-title">
                  {{ p.title }}
                </div>
              </a></template
            >
            <!-- <template v-else>
              <div class="yingsai-img" :style="{ backgroundImage: `url(` + p.imgURL + `)` }" @click="timeoutDialog()"></div>
              <div class="text-title">
                {{ p.title }}
              </div>
            </template> -->
          </div>
        </div>
        <div class="second">
          <div class="image-img" v-for="p in ATLastList" :key="'' + ' ' + 'at_' + p.id">
            <!-- <template v-if="p.id >= 140"> -->
            <template>
              <a :href="'activityPage.html?code=' + p.id" target="blank">
                <div class="yingsai-img" :style="{ backgroundImage: `url(` + p.imgURL + `)` }"></div>
                <div class="text-title">
                  {{ p.title }}
                </div>
              </a></template
            >
            <!-- <template v-else>
              <div class="yingsai-img" :style="{ backgroundImage: `url(` + p.imgURL + `)` }" @click="timeoutDialog()"></div>
              <div class="text-title">
                {{ p.title }}
              </div>
            </template> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from '@/api/RD'

export default {
  name: 'IndexAT',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      logined: false,
      webItem: {},
      ATLastList: [],
      ATFourList: [],
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      setTimeout(() => {
        this.content.list.forEach((i) => {
          i.imgURL = RD.activityTopic().id(i.id).headPic_URL(0)
        })

        this.webItem = this.content
      }, 1000)
      this.content.list.forEach((i, index) => {
        i.imgURL = RD.activityTopic().id(i.id).headPic_URL(0)
        if (index == 1 || index == 0 || index == 3 || index == 2) {
          thiz.ATFourList.push(i)
        }
        if (index == 4 || index == 5 || index == 6 || index == 7) {
          thiz.ATLastList.push(i)
        }
      })
      //   if (this.userData.approveStatus == 'APPROVED') {
      //     this.logined = true
      //   } else {
      //     this.logined = false
      //   }
    },
    // 摄影活动的图片点击进入详情
    onActivitydetail(postId) {
      let routerUrl = this.$router.resolve({
        path: '/activityPage.html',
        query: { code: postId },
      })
      window.open(routerUrl.href, '_blank')
    },

    // 报名参赛
    onGoactivity(postId) {
      var thiz = this
      if (thiz.userData.approveStatus == 'APPROVED') {
        let routerUrl = this.$router.resolve({
          path: '/upPost.html',
          query: { ftId: postId },
        })
        window.open(routerUrl.href, '_blank')
      } else {
        thiz.alert('当前账户未通过审核')
      }
    },
    onMoreAT() {
      console.log('op', postId)
      if (postId == undefined) {
        this.$message('没有稿件ID')
        this.$router.push('/picture.html')
      } else {
        this.$router.push({
          path: '/activityPage.html',
          query: { code: postId },
        })
      }
    },
    timeoutDialog() {
      this.$alert('活动已经过期', '已经过期')
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .image {
    width: 750px;
    height: auto;
  }
  .image-list {
    height: 447px;
    width: 750px;
    .list-con,
    .second {
      height: 50%;
      width: 1198px;
      display: flex;
      flex-wrap: wrap;
      border: 0.1rem solid #ccc;
      box-shadow: 0 0 1rem #ccc;
      margin: 3rem auto;
      a {
        text-decoration: none;
        color: #000;
      }
      .image-img {
        width: 26.5rem;
        height: 17.1rem;
        margin: 2rem 1.5rem;
        position: relative;
        cursor: pointer;
        .yingsai-img {
          background: no-repeat 50% / cover;
          height: 95%;
        }
        .text-title {
          width: 27.6rem;
          height: 2rem;
          line-height: 2rem;
          margin-top: 1rem;
          font-size: 1.3rem;
          top: 18.2rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .image {
    height: auto;
    display: flex;
    width: 120rem;
    margin: 0 auto;
    margin-bottom: 7rem;
    .web-item {
      .item-title-container {
        .item-title {
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .web-item-more {
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .image-list {
    height: auto;
    width: 100%;
    .list-con,
    .second {
      height: 46%;
      width: 119.8rem;
      display: flex;
      flex-wrap: wrap;
      border: 0.1rem solid #ccc;
      box-shadow: 0 0 1rem #ccc;
      margin: 3rem auto;
      a {
        text-decoration: none;
        color: #000;
      }
      .image-img {
        width: 26.5rem;
        height: 26.1rem;
        margin: 2rem 1.5rem;
        position: relative;
        cursor: pointer;
        .yingsai-img {
          background: no-repeat 50% / cover;
          height: 95%;
        }
        .text-title {
          width: 27.6rem;
          height: 2rem;
          line-height: 2rem;
          margin-top: 1rem;
          font-size: 1.3rem;
          top: 18.2rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

