<template>
  <div class="web-item">
    <div class="item-title-container">
      <div class="item-title" @click="$parent.onClickTopic(content.id)">
        {{ content.title }}
      </div>
    </div>
    <div class="local-con">
      <div class="local-list" v-if="content.list[0]">
        <div
          class="local-list-name"
          v-for="(k, localIndex) in content.list[0]"
          :key="'local' + k.id"
          @click="onLocal(localIndex)"
          :class="{ active: content.localIndex === localIndex }"
        >
          {{ k.name }}
        </div>
      </div>
      <div class="local-detail">
        <div class="local-detail-list" v-for="(k, lcindex) in localPostList" :key="k.id + 'lc_' + lcindex" @mouseenter="localStopTimer()" @mouseleave="localStartTimer()">
          <div class="local-detail-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onClickLocal(k.id)"></div>
          <div class="local-detail-text" @click="onClickLocal(k.id)">
            {{ k.title }}
          </div>
          <div class="local-detail-picsnumber">
            {{ k.upTime }}
            <span style="margin-left: 1.7rem">{{ k.countPicOnnet }}张</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var localTimer = []
import RD from '@/api/RD'

export default {
  name: 'IndexLc',
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {}
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data: function () {
    return {
      localPostList: [], // 当前显示的城市list
      lcIndex: -1, // 被显示的城市index
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.list.forEach((k) => {
          k.forEach((i) => {
            if (k.length === 1) {
              i.AddrImgURL = RD.addr().id(i.id).bgImage_URL()
            } else {
              i.AddrImgURL = RD.addr().id(i.id).bgImage_URL()
            }
          })
        })

        this.onLocal(0)
        localTimer = setInterval(() => {
          this.nextLocal()
          this.localPost()
        }, 5000)
      }, 1000)
    },
    localStopTimer() {
      clearInterval(localTimer)
    },
    localStartTimer() {
      if (localTimer) {
        clearInterval(localTimer)
      }
      localTimer = setInterval(() => {
        this.nextLocal()
        this.localPost()
      }, 5000)
    },
    nextLocal() {
      var thiz = this
      thiz.content.localIndex++
      if (thiz.content.localIndex == thiz.content.list[0].length) {
        thiz.content.localIndex = 0
      }
      thiz.lcIndex = thiz.content.localIndex
    },
    // 更多城市信息
    onMoreLocal() {
      this.$router.push({
        path: '/local.html',
      })
    },
    localPost() {
      if (this.content.list[0]) {
        var thiz = this
        var query = {}
        var page = 1
        // console.log('hkss', this.content.list[0], thiz.content.localIndex)
        query.addrId = this.content.list[0][thiz.content.localIndex].id

        query.storeIds = [1]
        this.localPostLoading = true
        RD.pure()
          .query()
          .query(query, page, 10)
          .then((items) => {
            var tempPostList = []
            items.list.forEach((k, index) => {
              k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
              if (index == 1 || index == 0 || index == 2 || index == 3 || index == 4 || index == 5 || index == 6) {
                tempPostList.push(k)
              }
            })
            thiz.localPostList = tempPostList
            thiz.localPostLoading = false
          })
      }
    },
    // 城市图库的点击
    onClickLocal(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 点击城市
    onLocal(index) {
      var thiz = this
      this.content.localIndex = index
      thiz.localPost()
      thiz.lcIndex = index
    },
  },
}
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .local-con {
    .local-list {
      width: 320px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .local-list-name {
        height: auto;
        transition: all 400ms ease-in-out;
        font-size: 14px;
        width: auto;
        padding: 5px 15px;
        border: 1px solid #ccc;
        border-radius: 15px;
        margin-right: 5px;
        margin-top: 5px;
        background-color: #ebedf1;
        cursor: pointer;
      }

      .active {
        background-color: #d42d26;
        color: #fff;
      }
    }

    .local-detail {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-shrink: 0;
      margin-top: 30px;
      overflow: hidden;
      transition: all 400ms ease-in-out;

      .local-detail-list {
        height: 126px;
        width: 173px;
        margin-right: 6px;
        background-color: #ebedf1;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;

        .local-detail-img {
          height: 100%;
          width: 100%;
          background: no-repeat 50% / cover;
          cursor: pointer;
          transition: all 300ms ease-in-out;
        }

        .local-detail-text {
          height: 25px;
          width: 100%;
          line-height: 25px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          color: #000;
          cursor: pointer;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: 0px;
          background-color: rgba(255, 255, 255, 0.4);
          transition: all 400ms ease-in-out;
          padding: 0px 0.42rem;
        }

        .local-detail-picsnumber {
          height: 2.5rem;
          width: 100%;
          line-height: 2.5rem;
          font-size: 1.2rem;
          position: absolute;
          bottom: -8.33rem;
          background-color: rgba(255, 255, 255, 0.4);
          transition: all 600ms ease-in-out;
          left: 50%;
          transform: translate(-50%);
        }
      }

      .local-detail-list:nth-child(1) {
        width: 365px;
        height: 126px;
        margin-right: 5px;
        background-color: #ebedf1;
      }

      .local-detail-list:hover {
        .local-detail-text {
          bottom: 25px;
          border-radius: 5px;
          width: 90%;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .local-detail-picsnumber {
          bottom: 0.42rem;
          width: 90%;
          border-radius: 0.42rem;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .local-detail-img {
          transform: scale(1.05);
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .local-con {
    width: 120rem;
    margin: 0 auto;
    .local-list {
      width: 53rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .local-list-name {
        height: auto;
        transition: all 400ms ease-in-out;
        font-size: 1.5rem;
        width: auto;
        padding: 0.85rem 2.5rem;
        border: 1px solid #ccc;
        border-radius: 2.5rem;
        margin-right: 0.85rem;
        margin-top: 0.85rem;
        background-color: #ebedf1;
        cursor: pointer;
      }

      .active {
        background-color: #d42d26;
        color: #fff;
      }
    }

    .local-detail {
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-shrink: 0;
      margin-top: 3.33rem;
      overflow: hidden;
      transition: all 400ms ease-in-out;

      .local-detail-list {
        height: 20.75rem;
        width: 28.6rem;
        margin-right: 0.66rem;
        background-color: #ebedf1;
        margin-bottom: 1.7rem;
        position: relative;
        overflow: hidden;

        .local-detail-img {
          height: 20.75rem;
          width: 100%;
          background: no-repeat 50% / cover;
          cursor: pointer;
          transition: all 300ms ease-in-out;
        }

        .local-detail-text {
          height: 2.5rem;
          width: 100%;
          line-height: 2.5rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1.3rem;
          color: #000;
          cursor: pointer;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: 0px;
          background-color: rgba(255, 255, 255, 0.4);
          transition: all 400ms ease-in-out;
          padding: 0px 0.42rem;
        }

        .local-detail-picsnumber {
          height: 2.5rem;
          width: 100%;
          line-height: 2.5rem;
          font-size: 1.2rem;
          position: absolute;
          bottom: -8.33rem;
          background-color: rgba(255, 255, 255, 0.4);
          transition: all 600ms ease-in-out;
          left: 50%;
          transform: translate(-50%);
        }
      }

      .local-detail-list:nth-child(1) {
        width: 58.8rem;
        height: 20.75rem;
        margin-right: 0.85rem;
        background-color: #ebedf1;
      }

      .local-detail-list:hover {
        .local-detail-text {
          bottom: 3.75rem;
          border-radius: 0.42rem;
          width: 90%;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .local-detail-picsnumber {
          bottom: 0.42rem;
          width: 90%;
          border-radius: 0.42rem;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .local-detail-img {
          transform: scale(1.05);
        }
      }
    }
  }
}
</style>

